import React, { useState, useEffect } from 'react';
import {  useSelector } from 'react-redux';
import { compPricingPricingDetail} from '../../urlConstants'
import restUtils from '../../utils/restUtils';
import _ from 'lodash';
import { useImmer } from 'use-immer';
import { header } from '../../components/GridTable/gridHeader'
import utilities from '../../utils/utilities';
import { Loader } from 'semantic-ui-react';

const PriceDetails = (props)=>{
    const { storeId } = useSelector(state => state.SessionReducer)
    const { sku, compId} = props
    const [ gridData, setGridData] = useImmer({
        rowData: [],
        totalData:[]
    })
    const [ sortCol, setSortCol] = useImmer({
        colIndex:4,
        orderAsc:false,
      })
   const [isLoading, setIsLoading] = useState(false)
     useEffect(()=>{
    
        const payLoad = {
            "memberNbr": storeId,	
            "skuNbr": sku,
            "compId": !_.isEmpty(compId) ? compId :  null,		
            "sortColumn": sortCol.colIndex,
            "sortOrder": sortCol.orderAsc ? 'asc': 'desc'
          }
          setIsLoading(true)
          restUtils.postData(compPricingPricingDetail, payLoad).then(response=>{
            const data = response.data
            setGridData(draft=>{
                draft.rowData = data.tvSkuCompDetail;
                draft.totalData = data.skuTotal;
              })
              setIsLoading(false)
          }).catch(err=>{
            setIsLoading(false)
          })
     },[sortCol,compId, sku, storeId])
     const handleSort = (cell, index)=>{
        setSortCol(draft=>{
         draft.colIndex = index;
         draft.orderAsc = index === 4 ? !draft[cell.key] : draft[cell.key]
         draft[cell.key] = !draft[cell.key]
        })
       }
    return (
      <>
      { isLoading && <Loader active/> }
        {gridData.rowData ? 
            gridData.rowData.length > 0 && (
                    <GridTable 
                      data = {gridData}
                      sortCol = {sortCol}
                      handleSort ={handleSort}
                      defaultSort= {4}
                      id="priceDetailPopUp"
                      />
                 ): <p className='noDataMsg'>No Competitor information available for Sku #{sku}</p>} </>)
}
const GridTable = (props) =>{
    const { rowData,  totalData} = props.data
    const { sortCol} = props
    const renderTotal = () =>{
          return (
            <>
            <td colSpan={3}>Average</td>
            <td style={{background:'#e9c46a'}}>{utilities.commify(totalData.avgCompPrice)}</td>
            <td></td>
            </>
        )
    }


     return (
        <>
        <div className="gridTableWrapper" id={props.id}>
        </div>
        <div className="gridTable">      
          <table style={{width:"100%"}}>
             <thead>
                <tr>
                  {header[props.id].map((cell,index)=>{        
                     return(
                        (index+1) === props.defaultSort ? (                     
                           <th onClick={()=>{props.handleSort(cell,index+1)}} key={cell.key} data-state={cell.name}>               
                           {cell.name} {(index+1) === sortCol.colIndex && <i className={ !sortCol.key === '' || sortCol[cell.key] ? 'caret down icon': 'caret up icon'} />}
                        </th>
                        ):<th className={cell.key ==='shortDesc'? "descrptionRow":''}  onClick={()=>{props.handleSort(cell,index+1)}} key={cell.key} data-state={cell.name}>               
                        {cell.name} {cell.key !== "checkbox" && (index+1) === sortCol.colIndex && <i className={ sortCol.colIndex === sortCol.key === '' || !sortCol[cell.key] ? 'caret down icon': 'caret up icon'} />}
                        </th>
                     )
                    })}
                </tr>
             </thead>
             <tbody>
                   {rowData.map((cell,index)=>{
                      return(
                         <tr  key={cell.compSku}>
                            {header[props.id].map(val=>{
                              return <td key={cell[val.name]}>{utilities.hyphenForNull(cell[val.key])}</td>  
                            })}
                         </tr>
                         
                      )
                   })}
             </tbody>
             <tfoot>
                <tr key='totalRow'>
                   {renderTotal()}               
                </tr>
             </tfoot>
          </table>
        </div>
  
        </>
     )
}
export default PriceDetails