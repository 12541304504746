import React  from 'react';
const NeedHelp = (props) =>{

    return(
        <>
        <div>
            <h3>Price Sensitivity Levels:</h3>
            <p>Level 6: Very Sensitive.</p>
            <p>Level 5: Moderately Sensitive.</p>
            <p>Level 4: Slightly Sensitive.</p>
            <p>Level 3: Slightly Insensitive.</p>
            <p>Level 2: Moderately Insensitive.</p>
            <p>Level 1: Very Insensitive.</p>
            <h3>Store Financials</h3>
            <p>YTD:	Beginning of year until now.</p>
            <p>YTD LY: Year to Date for the same period last year.</p>
            <p>LTM:	The most recent 12 months.</p>
            <p>LY LTM: The most recent 12 months of the same period last year.</p>
        </div>
        <div>
            <h3>Competitor Prices:</h3>
            <p>UPC Match: Items with exact UPC match.</p>
            <p>Model Match: Items with similar model numbers.</p>
            <p>Similar: Items with similar names. These may not be exact match but gives you a range of items for you to review.</p>
            <p>For any questions or requests, please email to <a href = ">merchandise.pricing@truevalue.com">merchandise.pricing@truevalue.com</a></p>
         </div>
        </>
    )
}

export default NeedHelp