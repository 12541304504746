import React, { Component } from "react";
import ReduxToastr from "react-redux-toastr";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import RenderRoutes from "./views/common/RenderRoutes";


class App extends Component {
  render() {
    return (
        <div>
          <ReduxToastr
            timeOut={4000}
            newestOnTop
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
          <RenderRoutes />
          <div id="snackbar"></div>
        </div>
    );
  }
}
export default App