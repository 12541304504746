import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  authorizeUser,
  getSecretKey,
  setAuthentication,
  login,
  logout,
} from '../Login/actions';
import jwtDecode from 'jwt-decode';
import utilities from '../../utils/utilities';
import Auth from '../../auth';
import history from '../../history';
import {finelinehiearchy, getRedirectUriMOLVariablePrice,getRedirectUriMOLRpo, getStoreInfo, getRedirectUriMOL} from '../../urlConstants'
import { restUtils } from '../../utils/restUtils';
import { setDropDownData }  from '../../components/DropDown/dropdownSlice'
class AuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errorMsg: '',
      isAuthenticated: props.isAuthenticated,
      userName:'',
      storeid:'',
      getprefer:{},
      prevUrlRedirect: false
    };
    this.auth = new Auth();
  }

  /* calls the logout method in authentication service */
  logout = () => {
    this.props.logout();            /* clearing redux */
    this.auth.removeHash();         /* clearing window hash */
    localStorage.clear();           /* clearing local storage */
    this.auth.logout();
  };

  componentDidMount() {
    if(!localStorage.getItem('access_token') && (!localStorage.getItem('isAuthInvoked')||
    (localStorage.getItem('isAuthInvoked') && this.props.storeId))) {
      console.log("isAuthInvoked"+localStorage.getItem("isAuthInvoked"));
      console.log("isAuthInvoked"+localStorage.getItem("access_token"));
      console.log("isAuthInvoked"+localStorage.getItem("isLeaveNW"));
      console.log("reauthenticating - ");
      this.auth.login();
    } else if (!localStorage.getItem('access_token')) {
      console.log("isAuthInvoked"+localStorage.getItem("isAuthInvoked"));
      console.log("isAuthInvoked"+localStorage.getItem("access_token"));
      console.log("isAuthInvoked"+localStorage.getItem("isLeaveNW"));
      console.log("autnentication invoked but no token received yet");
      this.handleAuthentication();
    } else if(localStorage.getItem('access_token') && !this.props.access_token){
        let id_token = jwtDecode(localStorage.getItem('id_token'));
        let access_token = jwtDecode(localStorage.getItem('access_token'));
        this.retrieveDetails(id_token,
          access_token,
          localStorage.getItem('expires_at')
        );
    } else {
        if(localStorage.getItem('access_token') && this.props.secretKey){
          this.auth.validateToken(this.props.secretKey);
        }
    }
  }

  shouldComponentUpdate(nextProps,nextState){
    return (!nextState.isAuthenticated || nextProps.storeId) && !this.state.prevUrlRedirect;
  }
  componentDidUpdate(){
    if(!localStorage.getItem('access_token') && (!localStorage.getItem('isAuthInvoked')||
    (localStorage.getItem('isAuthInvoked') && this.props.storeId))) {
      if(localStorage.getItem('isLeaveNW')){
        this.auth.clearStorage();
        return;
      }
      this.auth.login();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.isAuthenticated !== nextProps.isAuthenticated) {
      this.setState({ isAuthenticated: nextProps.isAuthenticated });
    }
  }
  
  componentDidCatch(error, errorInfo) {
    this.setState({ isError: true, errorMsg: error.message });
    if (error.message === 'Not Authenticated') {
      this.setState({ hasError: true, isAuthenticated: false });
      this.props.setAuthentication(false);
    }
  }
  getUser = userObj => {
    /* should accept storeid and user id */
    utilities.setLoader(true)  
    restUtils.getData(`${getStoreInfo}/${userObj.storeId}`).then(response=> {
      const storeDetails = response.data
      if(!storeDetails){
        utilities.showToast("User and Store details unavailable!");
      }      
      if(isNaN(storeDetails.memberNbr)) {
        if(userObj.storeId.toString() === '00001') {
          window.location.assign(getRedirectUriMOLRpo());
          localStorage.clear();
          sessionStorage.clear()
          return
        }
        window.location.assign(getRedirectUriMOLVariablePrice());
        localStorage.clear();
        sessionStorage.clear()
        return
      }
      this.props.login(
        utilities.emptyForNull(userObj.userName),
        storeDetails.memberNbr,
        storeDetails.memberName,
        utilities.emptyForNull(storeDetails?.programCode),
        utilities.emptyForNull(storeDetails?.multiStoreGroup),
        true,
        userObj.viewOnly,
        storeDetails 
      );
      this.props.dispatch(setDropDownData(`${finelinehiearchy}/${userObj.storeId}`))
    }).catch(err=>{
      console.log(err)
      if(userObj.storeId.toString() === '00001') {
        window.location.assign(getRedirectUriMOLRpo());
        localStorage.clear();
        sessionStorage.clear()
        utilities.setLoader(false);
        return
      }
      window.location.assign(getRedirectUriMOLVariablePrice());
      localStorage.clear();
      sessionStorage.clear()
      utilities.setLoader(false);
    });    
    utilities.setLoader(false)
      this.setState({ isAuthenticated: true, userName:userObj.userName, storeid: userObj.storeId});
      this.props.setAuthentication(true);
      if(localStorage.getItem('prevUrl') 
      && (localStorage.getItem('prevUrl') !== '/' ) && localStorage.getItem('prevUrl') !== '/#identity') {
        let redirecturl = localStorage.getItem('prevUrl')
        this.setState({prevUrlRedirect: true});
        history.push(redirecturl);
        this.setState({prevUrlRedirect: false});
      } else {
        history.push('/home');
      }
  };

  handleAuthentication = () => {
    console.log("trying to parse Hash")
    if (/access_token|id_token|error/.test(window.location.hash)) {
      console.log("hash Found");
      const authObj = this.auth.handleAuthentication(window.location.hash);
      const { id_token, access_token, expires_at } = authObj;
      this.props.authorizeUser(id_token, access_token, expires_at);
      this.props.getSecretKey(access_token);
      let isAuthenticated = !utilities.isEmptyOrNullString(access_token);
      localStorage.setItem('expires_at', expires_at);
      let userObj = {
        userName: id_token.username,
        viewOnly: (access_token.viewonly === 'Y') ? true :  false,   /* Remove user name later */
        csr: access_token.csr,
        csrPermissions: access_token.csr_permissions,
        CatAccess: access_token.cataccess,
        PogAccess: access_token.pogaccess,
        CatMbr: access_token.catmbr,
        PogMbr: access_token.pogmbr,
        tvr: access_token.tvr,
        managers: id_token.managers,
        MultiStoreOrdering: id_token.multistoreordering,
        storeId: utilities.padWithZeros(access_token.storeid, 5),
        appName: 'NW'
      };
      if (isAuthenticated) {
        this.getUser(userObj);        /* supposed to pass storeid and user id from id token */
      }
    }else{
      console.log("hash Not Found - Re authenticating");
      this.auth.login();
    }
  };

  retrieveDetails = (id_token, access_token, expires_at) =>{   
    this.props.authorizeUser(id_token, access_token, expires_at);
    this.props.getSecretKey(access_token);
    let isAuthenticated = !utilities.isEmptyOrNullString(access_token);
    let userObj = {
      userName: id_token.username,
      viewOnly: ( access_token.viewonly === 'Y') ? true :  false,   /* Remove user name later */
      csr: access_token.csr,
      csrPermissions: access_token.csr_permissions,
      CatAccess: access_token.cataccess,
      PogAccess: access_token.pogaccess,
      CatMbr: access_token.catmbr,
      PogMbr: access_token.pogmbr,
      tvr: access_token.tvr,
      managers: id_token.managers,
      MultiStoreOrdering: id_token.multistoreordering,
      storeId: utilities.padWithZeros(access_token.storeid, 5),
      appName: 'NW'
    };
    if (isAuthenticated) {
      this.getUser(userObj);        /* supposed to pass storeid and user id from id token */
    }
  }
  render() {
    const { isAuthenticated } = this.state;
    const { children } = this.props;
    if (
      isAuthenticated &&
      (!children ||
        (children.props.location &&
          children.props.location.pathname &&
          children.props.location.pathname === '/'))
    ) {
      if (localStorage.getItem('access_token')) {
        if(window.location.hash === '#identity' && (localStorage.getItem('prevUrl') === '/#identity' || localStorage.getItem('prevUrl') === '/home' || localStorage.getItem('prevUrl') === '/')) {
          window.location.assign(getRedirectUriMOL());
          this.props.logout();
          localStorage.clear();
          sessionStorage.clear();
        }
          history.push('/home');
      }
      return <></>;
    }
    return (
      <div>
        {isAuthenticated && <>{this.props.children}</>}
        {!isAuthenticated && (
          <div className='container column'>
            <h5> Redirecting....</h5>
          </div>
        )}
      </div>
    );
        
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.SessionReducer.isAuthenticated,
    secretKey:state.SessionReducer.secretKey,
    access_token:state.SessionReducer.access_token,
    programCode: state.SessionReducer.programCode,
    storeId: state.SessionReducer.storeId
  };
};
function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
       {
        authorizeUser,
        getSecretKey,
        setAuthentication,
        login,
        logout, 
        setDropDownData        
},
dispatch
)
);
}
export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
