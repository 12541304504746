import { LOGOUT, AUTHORIZATION, SET_AUTHENTICATION, GET_SECRET, LOGIN, CHANGE_STORE } from './actions';

const initState = { isAuthenticated: false, storeId: null };
const SessionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case LOGIN:
      return { ...state,
        userName: payload.username,
        storeId:payload.storeId,
        memberName: payload.memberName,
        multiStoreGroup: payload.multiStoreGroup,
        programCode: payload.programCode,
        isAuthenticated: payload.isAuthenticated,
        storeDetails:payload.storeDetails,
        viewOnly:payload.viewOnly,
        multiStoreDetails:payload.multiStoreDetails        
      };
    case AUTHORIZATION :
      const {id_token,access_token,expiresAt} = payload;
      return {
        ...state,
        id_token,
        access_token,
        expiresAt,
        isAuthenticated:true,
    
      };
    case SET_AUTHENTICATION :
      return{ ...state,
        isAuthenticated:payload.isAuthenticated
      }
    case LOGOUT:
      return {
        isAuthenticated: false,
      };
    case GET_SECRET :
      return {
        ...state, secretKey:payload
      }
    case CHANGE_STORE :
        return {
          ...state, currentStore:payload.user
        }
    default:
      return state;
  }
};

export default SessionReducer;
