import _ from "lodash";
import store from "../configureStore";
import { setCommonLoader } from "./utilitySlice";
import jwt from "jsonwebtoken";
import { logout } from "../views/Login/actions";

export const utilities = {
  emptyForNull: (value) => {
    return value && value !== "" && value !== "0" ? value : "";
  },
  hyphenForNull: (value) => {
    if (typeof value == "boolean") {
      return value ? "Yes" : "No";
    }
    return value && value !== "" ? value : " - ";
  },
  isEmptyOrNullString: (stringOrObjectOrNull) => {
    return (
      _(stringOrObjectOrNull).toString().trim() === "" ||
      _.isEmpty(stringOrObjectOrNull.toString())
    );
  },
  decodeToken: function (token) {
    try {
      // if the token has more or less than 3 parts or is not a string
      // then is not a valid token
      if (token.split(".").length !== 3 || typeof token !== "string") {
        return null;
      }

      // payload ( index 1 ) has the data stored and
      // data about the expiration time
      const payload = token.split(".")[1];
      // determine the padding characters required for the base64 string
      const padding = "=".repeat((4 - (payload.length % 4)) % 4);
      // convert the base64url string to a base64 string
      const base64 = payload.replace("-", "+").replace("_", "/") + padding;
      // parse base64 into json
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      // decode json
      const decoded = JSON.parse(jsonPayload);

      return decoded;
    } catch (error) {
      // Return null if something goes wrong
      return null;
    }
  },
  validateToken: function (secretKey) {
    const access_token = localStorage.getItem("access_token");
    let publicKey = `-----BEGIN CERTIFICATE-----${secretKey}-----END CERTIFICATE-----`;
    jwt.verify(
      access_token,
      publicKey,
      { algorithm: "RS256", ignoreNotBefore: true },
      (err, decoded) => {
        if (err) {
          console.log(err);
          this.showToast("Session timed out");
          store.dispatch(logout());
          localStorage.clear();
          sessionStorage.clear();
          return false;
        }
        return true;
      }
    );
  },
  setLoader: function (showLoader) {
    store.dispatch(setCommonLoader(showLoader));
  },
  replaceAll: (string, find, replaceStr) => {
    if (!string || string === null) {
      return "";
    }
    return string.toString().replace(new RegExp(find, "g"), replaceStr);
  },

  showToast: (toastText, isError) => {
    var x = document.getElementById("snackbar");
    if (x) {
      const className = isError ? "red show" : "show";
      x.innerHTML = utilities.replaceAll(toastText, "\n", "<br/>");
      x.className = className;
      setTimeout(function () {
        x.className = x.className.replace(className, "");
      }, 5000);
    }
  },
  padWithZeros: (value, reqLength) => {
    value = value.toString();
    const strLength = value.length;
    return reqLength <= strLength
      ? value
      : new Array(reqLength - strLength + 1).join("0") + value;
  },
  hasParentWithMatchingSelector: (target, selector) => {
    return document.querySelectorAll("#" + selector)[0].contains(target);
  },
  formatNumber: (num, rounded = false, precision = 2) => {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      let formatted = (num / found.threshold).toFixed(precision) + found.suffix;
      if (rounded) {
        formatted =
          Math.round((num / found.threshold).toFixed(precision)) + found.suffix;
      }
      return formatted;
    }

    return num;
  },
  randomBackGround: () => {
    const x = Math.floor(Math.random() * 256);
    const y = Math.floor(Math.random() * 256);
    const z = Math.floor(Math.random() * 256);
    return "rgb(" + x + "," + y + "," + z + ")";
  },
  commify: (n) => {
    let parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  },
};

export default utilities;
