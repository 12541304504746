import React from 'react';
import { Link } from 'react-router-dom';
const About = (props) => {
    return (
        <>
          <p>About 1</p>
        <Link to={'/home'}>home</Link>
        </>     
        
    )
}

export default About