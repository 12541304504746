
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDropDownShow} from '../../../components/DropDown/dropdownSlice'
import restUtils from '../../../utils/restUtils';
import DropDown from './DropDown';
import { useImmer } from 'use-immer';
import  usePrevious from '../usePrevious'
import _ from 'lodash';
const CustomDropDown = (props) =>{
    const { storeId } = useSelector(state => state.SessionReducer)
    const dispatch = useDispatch();
    const { competitorData = []} = props
    const [ dropDownData, setDropDownData] = useImmer({
        finelineHierarchy:[],
        category:[],
        class:[],
        subClass:[],
        sku:[],
        competitor:competitorData
    })
    useEffect(()=>{
      setDropDownData(draft=>{
        draft.competitor = props.competitorData
      })
    },[props.competitorData])
    const [ selectedItem, setSelectedItem] = useImmer({
        category:[],
        class:[],
        subClass:[],
        sku:[],
        skuType: "-1",
    })
    const [ selectedComp, setSelectedComp] = useState([])
    const [filterType, setFilterType] = useImmer({
        name: 'None',
        value:[],
        competitor:[]
    })
    const [tempFilterType, setTempFilterType] = useImmer({
      name: 'None',
      value:[],
      competitor:[]
  })
  const prevFilter = usePrevious(filterType)
  const skuTypeDropDown = [
    { name: "All", value: "-1" },
    { name: "TV", value: "1" },
    { name: "Non-TV", value: "0" },
  ];
      const handleSelectedCategory = (items) => {
        const values = items.map(item => item.value)
        const data = dropDownData.finelineHierarchy.filter((ele) => {
            if(values.includes(ele.deptCode)) {
              return ele
            }
            return null
          })
          setSelectedItem(draft=>{
            draft.category = items;
            draft.class = [];
            draft.subClass = []
            draft.sku = []
            draft.skuType = "-1";
        })
        let sortClass = _.uniqBy(data, obj=>obj.className)
        setDropDownData(draft=>{
            draft.class = _.orderBy(sortClass, ['className'], ['asc'])
        })
      }
      const handleSelectedClass  = (item) =>{
        const values = item.map(item => item.uniqCode)
        const data = dropDownData.finelineHierarchy.filter(ele=>{
            if(values.includes(ele.uniqClassId)) {
                  return ele
                }
                return null
            })
        setSelectedItem(draft=>{
            draft.class = item;
            draft.subClass = []
            draft.sku = []
            draft.skuType = "-1";
        })
        let sortSubClass = _.uniqBy(data, obj=>obj.subClassName)
        setDropDownData(draft=>{
            draft.subClass = _.orderBy(sortSubClass, ['subClassName'], ['asc'])
        })
      }
      const handleSelectedSubClass = (item) =>{
        setSelectedItem(draft=>{
          draft.subClass = item;
          draft.sku = []
          draft.skuType = "-1";
      })
      }
      const handleSelectedSku =(item)=>{
        setSelectedItem(draft=>{
          draft.sku = item
      })
      }
      const handleSelectedCompetitor = (item) =>{
      setSelectedComp(item)
          }    
      const handleFilterType = (item)=>{
        setTempFilterType(draft=>{
          draft.name = item.name;
          draft.value = item.value;
      })
      }
      const handleCompetitorValue = (val) =>{
        setTempFilterType(draft=>{
          draft.competitor = val
        })
        setFilterType(draft=>{
          draft.competitor = val
        })
      }
useEffect(()=>{
    if(!_.isEmpty(prevFilter) && !_.isEqual(prevFilter, filterType)) {
      props.sendFilterVal(filterType)
    }
},[filterType])
useEffect(()=>{
  if(tempFilterType.value !== null && tempFilterType.value.length === 0) {
    if(!_.isEmpty(selectedItem.subClass)) {
        setFilterType(draft=>{
          draft.name = 'SubClass';
          draft.value = selectedItem.subClass.map(ele=> ele.uniqCode);
        })
    }
    else if(!_.isEmpty(selectedItem.class)) {
      setFilterType(draft=>{
        draft.name = 'Class';
        draft.value = selectedItem.class.map(ele=> ele.uniqCode);
      })
    }
    else if(!_.isEmpty(selectedItem.category)) {
      setFilterType(draft=>{
        draft.name ='Dept';
        draft.value = selectedItem.category.map(ele=> ele.uniqCode);
      })
    }else {
      setFilterType(draft=>{
        draft.name = 'None';
        draft.value = [];
        draft.competitor = filterType.competitor
      })
    }
  }
    else {
      setFilterType(draft=>{
        draft.name = tempFilterType.name;
        draft.value = tempFilterType.value;
      })
    }  
},[selectedItem])
const handleSelectedSkuType = (event) => {
  const { value } = event.target;
  setSelectedItem((draft) => {
    draft.skuType = value;
  });
};
useEffect(() => {
  props.showSkuType === 'productMaster'&&
  props.sendSkuTypeVal(selectedItem.skuType);
}, [selectedItem.skuType]);
      useEffect(()=>{
        restUtils.getData(`${props.dropDownUrl}/${storeId}`).then(res=>{
          if(props?.page==='competitorPrices') {
            let sortDeptName = _.uniqBy(res.data.tvSkuHierarchy, obj=>obj.deptName)
            setDropDownData(draft=>{
              draft.finelineHierarchy = res.data.tvSkuHierarchy
              draft.category =  _.orderBy(sortDeptName, ['deptName'], ['asc'])
          })
          }else if(props.showSkuType === 'productMaster'){
            let sortDeptName = _.uniqBy(res.data.productMasterHierarchy, obj=>obj.deptName)
            setDropDownData(draft=>{
              draft.finelineHierarchy = res.data.productMasterHierarchy
              draft.category = _.orderBy(sortDeptName, ['deptName'], ['asc'])
          })
          }
          else {
            let sortDeptName = _.uniqBy(res.data.priceChangeHierarchy, obj=>obj.deptName)
            setDropDownData(draft=>{
              draft.finelineHierarchy = res.data.priceChangeHierarchy
              draft.category =_.orderBy(sortDeptName, ['deptName'], ['asc'])
          })
          }
         
        }).catch((err)=>{
         console.log(err)
        })
     },[storeId])
     useEffect(()=>{
      if(_.isEmpty(prevFilter) || (!_.isEmpty(prevFilter) && !_.isEqual(prevFilter, filterType))) {
        if(storeId &&  filterType.name !== 'Sku' && filterType.name !== 'Competitor' ) {
          let payload = {
            "filterType": filterType.name,
            "filterValues": filterType.value,
            "memberNbr": storeId,
          }
          const SkuPayload = {
          "filterType": filterType.name,
          "filterValues": filterType.value,
          "memberNbr": storeId,
          "skuType": parseInt(selectedItem.skuType),
        };
        payload = props.showSkuType === 'productMaster' ? SkuPayload : payload;
         restUtils.postData(props.skuDropDownUrl, payload).then(response=>{
            if(props?.page==='competitorPrices') {
              setDropDownData(draft=>{
                draft.sku =  _.uniqBy(response.data.tvSkus, (obj) => obj.skuNbr);
                })
            }else if(props.showSkuType === 'productMaster'){
            setDropDownData(draft=>{
              draft.sku = _.uniqBy(response.data.masterSkus, (obj) => obj.skuNbr);
              })
          }
            else {
              setDropDownData(draft=>{
              draft.sku = _.uniqBy(response.data.priceChangeSkus, (obj) => obj.skuNbr);
              })
            }
            
          })
        }        
      }
     
    },[storeId,filterType])
      useEffect(() => {
        const handleTabClose = event => {
          event.preventDefault();
          dispatch(setDropDownShow(false))
        };
    
        window.addEventListener('beforeunload', handleTabClose);
    
        return () => {
          window.removeEventListener('beforeunload', handleTabClose);
          dispatch(setDropDownShow(false))
        };
      }, []);
      const handleReset = () =>{
        setSelectedItem(draft=>{
          draft.category = [];
          draft.class = [];
          draft.subClass = []
          draft.sku = []
          draft.skuType = "-1";
        })
        setTempFilterType(draft=>{
          draft.name ='None';
          draft.value =[];
          draft.competitor = []
        })
        setFilterType(draft=>{
          draft.name ='None';
          draft.value =[];
          draft.competitor = []
        })
      
        setSelectedComp([])
        setDropDownData(draft=>{
          draft.class=[];
          draft.subClass=[];
        })
      }
      return (
        <>
            <div className='dropDownContainer'>
            <div>
                <h4>Category</h4>
                <DropDown id='Dept' data = {dropDownData.category}  selectedItem={selectedItem.category} handleSelectedItem={handleSelectedCategory} handleFilterType={handleFilterType}/>
            </div>
            <div>
                <h4>Class</h4> 
                <DropDown id='Class' data = {dropDownData.class}  selectedItem={selectedItem.class} handleSelectedItem={handleSelectedClass} handleFilterType={handleFilterType}/>
    
            </div>
            <div>
                <h4>Sub Class</h4>
                <DropDown id='SubClass' data = {dropDownData.subClass} selectedItem={selectedItem.subClass} handleSelectedItem={handleSelectedSubClass} handleFilterType={handleFilterType} />
    
            </div>
            {props.showSkuType === 'productMaster' && (
              <div>
                <h4>Sku Type</h4>
                <div className="skuTypeSelect">
                  <i className="caret down icon"></i>
                  <select
                    value={selectedItem.skuType}
                    onChange={handleSelectedSkuType}
                  //  disabled = {_.isEmpty(dropDownData.finelineHierarchy)}
                  >
                    {skuTypeDropDown.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            <div>
                <h4>Sku</h4>
                <DropDown id='Sku' data = {dropDownData.sku} selectedItem={selectedItem.sku}  handleSelectedItem={handleSelectedSku} handleFilterType={handleFilterType} />
     
            </div>
            {props?.page==='competitorPrices' && (
              <div>
               <h4>Competitor</h4>
               <DropDown id='Competitor' data = {dropDownData.competitor} selectedItem={selectedComp}  handleSelectedItem={handleSelectedCompetitor} handleCompetitorValue={handleCompetitorValue} />
               </div>
            )}
            <button className='dropdownResetBtn' onClick={handleReset}>Reset</button>
          </div>      
        </>
      )
}

export default CustomDropDown